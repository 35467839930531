<template>
  <div class="form-signin text-center">
    <img class="mb-4" src="@/assets/isu.png" alt height="125" />

    <label for="inputEmail" class="sr-only">Email address</label>
    <b-form-input v-model="username" type="text" class="form-control" placeholder="Username" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"/>

    <label for="inputPassword1" class="sr-only">Password</label>
    <b-form-input v-model="passwordone" type="password" class="form-control" placeholder="Password" />

    <label for="inputPassword2" class="sr-only">Confirm Password</label>
    <b-form-input v-model="passwordtwo" type="password" class="form-control" placeholder="Confirm Password" />

    <label class="sr-only">Firstname</label>
    <b-form-input v-model="firstname" type="text" class="form-control" placeholder="Firstname" />

    <label class="sr-only">Lastname</label>
    <b-form-input v-model="lastname" type="text" class="form-control" placeholder="Lastname" />

    <label for="accesscode" class="sr-only">Access Code</label>
    <b-form-input v-model="accesscode" type="text" class="form-control" placeholder="Access Code" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"/>
    {{message}}
    <button class="btn btn-lg btn-block" style="border: 1px solid gray; color: white; margin-top: 10px;" @click="createaccount">Register</button>
  </div>
</template>

<script>
import { api } from "@/utils/api";

export default {
  name: "Register",
  data() {
    return {
      username: "",
      passwordone: "",
      passwordtwo: "",
      firstname: "",
      lastname: "",
      accesscode: "",
      message: ""
    };
  },
  methods: {
    createaccount() {
      if (this.passwordone !== this.passwordtwo) {
        this.message = "Passwords don't equal";
      } else {
        let self = this;
        api(
          `mutation{ register(firstname:"${this.firstname}", lastname:"${this.lastname}", username:"${this.username}", password:"${this.passwordone}", accesscode:"${this.accesscode}") { message status } }`
        ).then(data => {
          if (data.register.status === 0) {
            self.$router.push({ name: "Dashboard" });
          } else {
            self.message = data.register.message;
          }
        });
      }
    }
  }
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
input {
  margin: 2.5px 0 2.5px 0;
}
input:focus input:active {
  outline: none;
}
</style>